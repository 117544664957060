import { Checkbox } from '../../../components/common/Checkbox/Checkbox'
import styles from '../Modal.module.scss'
import { motion } from 'framer-motion'
import { IconSvg } from 'components/common/IconSvg/IconSvg'
import { crossIconPath } from 'config/commonSvgIconsPath'
import { Button } from 'components/common/Button/Button'
import { studentsGroupT } from 'types/studentsGroup'
import { IInviteProgramResp } from 'api/apiTypes'
import React, { FC, useState } from 'react'
import { isCheckedFunc } from 'utils/isCheckedFunc'
import { useBoolean } from 'customHooks'
import { useUpdateInvitesProgramMutation } from 'api/schoolService'

export type InvitesGroupsT = {
  setShowModal: (value: boolean) => void
  groups: studentsGroupT
  invite: IInviteProgramResp
  schoolName: string
  refetch: () => void
}

export const InvitesProgramGroups: FC<InvitesGroupsT> = ({ refetch, schoolName, setShowModal, groups, invite }) => {
  const [activeGroups, setActiveGroups] = useState<number[]>(invite.groups)
  const [allGroups, setAllGroups] = useState<boolean>(activeGroups.length === groups.results.length)
  const [saveChanges, { isLoading }] = useUpdateInvitesProgramMutation()
  const [isEditing, { on: closeEditing, off: openEditing }] = useBoolean(false)

  const handleSelectAllGroups = (event: React.MouseEvent<HTMLButtonElement>) => {
    const groupsIds = groups?.results.map(group => Number(group.group_id))
    setActiveGroups(groupsIds)
  }

  const handleUnselectAllGroups = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActiveGroups([])
  }

  return (
    <motion.div
      className={styles.banner_groups}
      initial={{
        scale: 0.1,
        opacity: 0,
      }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        delay: 0.5,
      }}
    >
      <div className={styles.container}>
        <div className={styles.container_buttons}>
          <Button style={{ padding: '12px 30px' }} onClick={handleSelectAllGroups} variant={'newPrimary'} text={'Выбрать все группы'} />
          <Button style={{ padding: '12px 30px' }} onClick={handleUnselectAllGroups} variant={'cancel'} text={'Снять выделение со всех групп'} />
        </div>

        <span
          className={styles.main_closed}
          onClick={async () => {
            if (activeGroups.length != 0) {
              setShowModal(false)
              const formdata = new FormData()
              activeGroups.map(grp => formdata.append('groups', String(grp)))
              await saveChanges({ schoolName: schoolName, data: formdata, id: invite.id })
                .unwrap()
                .then(() => {
                  closeEditing()
                  refetch()
                })
            }
          }}
        >
          <IconSvg width={64} height={64} viewBoxSize="0 0 64 64" path={crossIconPath} />
        </span>

        {Object.entries(
          groups.results.reduce<Record<string, typeof groups.results>>((acc, group) => {
            const courseName = group.course_name
            if (courseName) {
              if (!acc[courseName]) {
                acc[courseName] = []
              }
              acc[courseName].push(group)
            }
            return acc
          }, {}),
        ).map(([courseName, groups]) => {
          const allGroupsChecked = groups.every(group => isCheckedFunc(group.group_id as number, activeGroups))
          const someGroupsChecked = groups.some(group => isCheckedFunc(group.group_id as number, activeGroups))

          const handleCourseCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const isChecked = e.target.checked
            if (isChecked) {
              setActiveGroups(prevGrps => [...prevGrps, ...groups.map(group => Number(group.group_id))])
            } else {
              setActiveGroups(prevGrps => prevGrps.filter(grp => !groups.map(group => Number(group.group_id)).includes(grp)))
            }
          }

          return (
            <div className={styles.courses_container} key={courseName} style={{ marginBlockStart: '3px' }}>
              <div className={styles.course_container}>
                <div>
                  <Checkbox checked={allGroupsChecked || someGroupsChecked} onChange={handleCourseCheckboxChange} />
                  <b>
                    {courseName} (групп: {groups.length})
                  </b>
                </div>
                {/* <button className={styles.to_select_groups}>
                                        <IconSvg width={14} height={15} viewBoxSize="0 0 14 15" path={arrowDownPoligonPath}></IconSvg>
                                    </button> */}
              </div>
              {groups.map(group => (
                <div key={group.group_id} className={styles.group_container}>
                  <Checkbox
                    checked={isCheckedFunc(group.group_id as number, activeGroups)}
                    onChange={e => {
                      const isChecked = e.target.checked
                      if (!isChecked) {
                        setAllGroups(false)
                        setActiveGroups(prevGrps => prevGrps.filter(grp => grp !== Number(group.group_id)))
                      } else {
                        setActiveGroups(prevGrps => prevGrps.concat(Number(group.group_id)))
                      }
                    }}
                  />
                  {group.name}
                  <span> (Кол-во студентов: {group.students.length})</span>
                </div>
              ))}
            </div>
          )
        })}
        <div className={`${styles.groups_warning} ${activeGroups.length != 0 ? '' : styles.groups_warning_active}`}>
          Должна быть выбрана хотя бы одна группа
        </div>
      </div>
    </motion.div>
  )
}
