export const aiButtonIcon = {
  d1: "M10.0565 14.1682C10.2089 13.7535 10.8171 13.7535 10.9695 14.1682L11.3863 15.3023C11.4351 15.435 11.5434 15.5395 11.681 15.5865L12.8571 15.9884C13.2872 16.1353 13.2872 16.7218 12.8571 16.8688L11.681 17.2706C11.5434 17.3177 11.4351 17.4221 11.3863 17.5549L10.9695 18.6889C10.8171 19.1037 10.2089 19.1037 10.0565 18.6889L9.63977 17.5549C9.59099 17.4221 9.48269 17.3177 9.34501 17.2706L8.16893 16.8688C7.73883 16.7218 7.73883 16.1353 8.16893 15.9884L9.34501 15.5865C9.48269 15.5395 9.59099 15.435 9.63977 15.3023L10.0565 14.1682Z",
  d2: "M11.8191 1.47278C12.0508 0.842406 12.9753 0.842406 13.2069 1.47278L14.0008 3.63332C14.075 3.8351 14.2396 3.99384 14.4488 4.06534L16.6894 4.83089C17.3431 5.05426 17.3431 5.94574 16.6894 6.16911L14.4488 6.93466C14.2396 7.00616 14.075 7.1649 14.0008 7.36668L13.2069 9.52722C12.9753 10.1576 12.0508 10.1576 11.8191 9.52722L11.0252 7.36668C10.9511 7.1649 10.7865 7.00616 10.5772 6.93466L8.33665 6.16911C7.68292 5.94574 7.68292 5.05426 8.33665 4.83089L10.5772 4.06534C10.7865 3.99384 10.9511 3.8351 11.0252 3.63332L11.8191 1.47278Z",
  d3: "M3.6661 7.36289C3.83755 6.87904 4.52183 6.87904 4.69327 7.36289L5.16209 8.68599C5.21697 8.84087 5.33881 8.96272 5.4937 9.0176L6.81679 9.48641C7.30065 9.65786 7.30065 10.3421 6.81679 10.5136L5.4937 10.9824C5.33881 11.0373 5.21697 11.1591 5.16209 11.314L4.69327 12.6371C4.52183 13.121 3.83755 13.121 3.6661 12.6371L3.19728 11.314C3.1424 11.1591 3.02056 11.0373 2.86568 10.9824L1.54258 10.5136C1.05872 10.3421 1.05872 9.65786 1.54258 9.48641L2.86568 9.0176C3.02056 8.96272 3.1424 8.84087 3.19728 8.68599L3.6661 7.36289Z",
}

export const arrowUpIcon = {
  d1: "M7.5 14.3438C7.5 14.896 7.94772 15.3438 8.5 15.3438C9.05229 15.3438 9.5 14.896 9.5 14.3438H7.5ZM9.5 3.1875C9.5 2.63521 9.05228 2.1875 8.5 2.1875C7.94772 2.1875 7.5 2.63521 7.5 3.1875L9.5 3.1875ZM8.5 2.65625L9.20711 1.94914C9.01957 1.76161 8.76522 1.65625 8.5 1.65625C8.23478 1.65625 7.98043 1.76161 7.79289 1.94914L8.5 2.65625ZM12.0429 7.61336C12.4334 8.00388 13.0666 8.00388 13.4571 7.61336C13.8476 7.22283 13.8476 6.58967 13.4571 6.19914L12.0429 7.61336ZM3.54289 6.19914C3.15237 6.58967 3.15237 7.22283 3.54289 7.61336C3.93342 8.00388 4.56658 8.00388 4.95711 7.61336L3.54289 6.19914ZM9.5 14.3438L9.5 3.1875L7.5 3.1875L7.5 14.3438H9.5ZM7.79289 3.36336L12.0429 7.61336L13.4571 6.19914L9.20711 1.94914L7.79289 3.36336ZM7.79289 1.94914L3.54289 6.19914L4.95711 7.61336L9.20711 3.36336L7.79289 1.94914Z",
}

export const messageIcon = {
  d1: "M8.81309 15.2073C8.35848 15.2073 7.93023 14.974 7.62716 14.5673L6.63889 13.234C6.61912 13.2073 6.54006 13.174 6.50712 13.1673H6.17769C3.4303 13.1673 1.73047 12.414 1.73047 8.66732V5.33398C1.73047 2.38732 3.26559 0.833984 6.17769 0.833984H11.4485C14.3606 0.833984 15.8957 2.38732 15.8957 5.33398V8.66732C15.8957 11.614 14.3606 13.1673 11.4485 13.1673H11.1191C11.0663 13.1673 11.0202 13.194 10.9873 13.234L9.99901 14.5673C9.69594 14.974 9.26769 15.2073 8.81309 15.2073ZM6.17769 1.83398C3.81902 1.83398 2.71874 2.94732 2.71874 5.33398V8.66732C2.71874 11.6807 3.73996 12.1673 6.17769 12.1673H6.50712C6.84313 12.1673 7.22526 12.3607 7.42951 12.634L8.41778 13.9673C8.64838 14.274 8.9778 14.274 9.2084 13.9673L10.1967 12.634C10.4141 12.3407 10.7567 12.1673 11.1191 12.1673H11.4485C13.8072 12.1673 14.9074 11.054 14.9074 8.66732V5.33398C14.9074 2.94732 13.8072 1.83398 11.4485 1.83398H6.17769Z",
  d2: "M8.81119 7.99935C8.44224 7.99935 8.15234 7.69935 8.15234 7.33268C8.15234 6.96602 8.44883 6.66602 8.81119 6.66602C9.17356 6.66602 9.47004 6.96602 9.47004 7.33268C9.47004 7.69935 9.18015 7.99935 8.81119 7.99935Z",
  d3: "M11.4479 7.99935C11.079 7.99935 10.7891 7.69935 10.7891 7.33268C10.7891 6.96602 11.0855 6.66602 11.4479 6.66602C11.8103 6.66602 12.1068 6.96602 12.1068 7.33268C12.1068 7.69935 11.8169 7.99935 11.4479 7.99935Z",
  d4: "M6.17838 7.99935C5.80942 7.99935 5.51953 7.69935 5.51953 7.33268C5.51953 6.96602 5.81601 6.66602 6.17838 6.66602C6.54075 6.66602 6.83723 6.96602 6.83723 7.33268C6.83723 7.69935 6.54733 7.99935 6.17838 7.99935Z",
}

export const ratingChangeIcon = {
  d1: "M30.5865 12.0937C29.6711 11.1067 28.2378 10.4936 26.8458 10.4936H23.1637C23.9923 9.31223 24.616 8.12342 24.9961 6.99139C25.576 5.26393 25.5557 3.75807 24.9373 2.63658C24.2408 1.37342 22.8292 0.677734 20.9628 0.677734C20.7552 0.677739 20.5523 0.732884 20.3799 0.836177C20.2074 0.93947 20.0731 1.08626 19.994 1.25793C18.5407 4.41127 14.7502 8.9435 11.1788 11.9291C10.8144 10.7967 9.64655 9.96561 8.26504 9.96561H3.77852C2.10646 9.96561 0.746094 11.1826 0.746094 12.6784V24.6108C0.746094 26.1066 2.10646 27.3236 3.77852 27.3236H8.26504C9.2879 27.3236 10.1935 26.8677 10.743 26.1716C11.5742 26.8871 12.6763 27.3236 13.8839 27.3236H25.1808C26.4297 27.3236 27.5418 26.8479 28.397 25.9479C29.1021 25.2059 29.6028 24.2035 29.845 23.0492L31.5101 15.114C31.7335 14.0494 31.4055 12.9767 30.5865 12.0937ZM9.20151 24.6108C9.20151 25.0728 8.78141 25.4487 8.26504 25.4487H3.77852C3.26215 25.4487 2.84198 25.0728 2.84198 24.6108V12.6784C2.84198 12.2165 3.26215 11.8407 3.77852 11.8407H8.26504C8.78141 11.8407 9.20151 12.2165 9.20151 12.6784V24.6108ZM29.4502 14.7681L27.7851 22.7033C27.5066 24.0305 26.6762 25.4487 25.1807 25.4487H13.8838C12.4577 25.4487 11.2975 24.2947 11.2975 22.8763V14.3368C15.2476 11.4031 19.6715 6.36467 21.6244 2.59885C22.6664 2.75658 22.9526 3.27555 23.0578 3.46609C23.7379 4.69955 23.1734 7.53965 20.3268 10.8606C20.2077 10.9994 20.1345 11.1653 20.1154 11.3394C20.0962 11.5135 20.132 11.6889 20.2185 11.8459C20.305 12.0028 20.4389 12.1349 20.605 12.2272C20.7711 12.3196 20.9628 12.3686 21.1584 12.3685H26.8459C27.6187 12.3685 28.454 12.7306 28.9736 13.2909C29.2662 13.6065 29.5865 14.1188 29.4502 14.7681Z",
}

export const userIcon = {
  d1: "M35.8911 34.9927C35.8511 34.9927 35.8245 34.9927 35.7845 34.9927C35.7178 34.9793 35.6245 34.9793 35.5445 34.9927C31.6778 34.8727 28.7578 31.8327 28.7578 28.086C28.7578 24.2727 31.8645 21.166 35.6778 21.166C39.4911 21.166 42.5978 24.2727 42.5978 28.086C42.5845 31.8327 39.6511 34.8727 35.9311 34.9927C35.9178 34.9927 35.9045 34.9927 35.8911 34.9927ZM35.6778 23.166C32.9711 23.166 30.7578 25.3793 30.7578 28.086C30.7578 30.7527 32.8378 32.8993 35.4911 32.9927C35.5578 32.9793 35.7445 32.9793 35.9178 32.9927C38.5311 32.8727 40.5845 30.726 40.5978 28.086C40.5978 25.3793 38.3845 23.166 35.6778 23.166Z",
  d2: "M35.9058 49.5673C33.2925 49.5673 30.6658 48.9006 28.6792 47.5673C26.8258 46.3406 25.8125 44.6607 25.8125 42.834C25.8125 41.0073 26.8258 39.314 28.6792 38.074C32.6792 35.4207 39.1592 35.4207 43.1325 38.074C44.9725 39.3007 45.9992 40.9806 45.9992 42.8073C45.9992 44.634 44.9858 46.3273 43.1325 47.5673C41.1325 48.9006 38.5192 49.5673 35.9058 49.5673ZM29.7858 39.754C28.5058 40.6073 27.8125 41.7007 27.8125 42.8473C27.8125 43.9807 28.5192 45.074 29.7858 45.914C33.1058 48.1406 38.7058 48.1406 42.0258 45.914C43.3058 45.0606 43.9992 43.9673 43.9992 42.8206C43.9992 41.6873 43.2925 40.594 42.0258 39.754C38.7058 37.5407 33.1058 37.5407 29.7858 39.754Z",

}

export const aiMobileButtonIcon = {
  d: "M23.0209 10.4313L1.9125 0.134385C1.70965 0.0355428 1.48498 -0.0100637 1.25965 0.0018576C1.03431 0.0137789 0.81571 0.0828359 0.624424 0.202529C0.433138 0.322222 0.275456 0.488615 0.166219 0.686047C0.0569813 0.88348 -0.000219245 1.10546 6.31509e-07 1.33109V1.36927C0.000108672 1.54765 0.0220924 1.72533 0.0654597 1.89835L2.09033 9.99712C2.11747 10.105 2.17696 10.202 2.26081 10.2751C2.34466 10.3483 2.44887 10.394 2.55945 10.4062L11.4586 11.3956C11.6067 11.4135 11.7431 11.4849 11.8421 11.5965C11.941 11.708 11.9957 11.852 11.9957 12.0011C11.9957 12.1502 11.941 12.2942 11.8421 12.4057C11.7431 12.5173 11.6067 12.5887 11.4586 12.6065L2.55945 13.596C2.44887 13.6082 2.34466 13.6539 2.26081 13.727C2.17696 13.8002 2.11747 13.8972 2.09033 14.0051L0.0654597 22.1027C0.0220924 22.2758 0.000108672 22.4534 6.31509e-07 22.6318V22.67C-3.40672e-05 22.8955 0.0573016 23.1174 0.166616 23.3147C0.27593 23.512 0.43363 23.6782 0.624887 23.7978C0.816143 23.9173 1.03467 23.9863 1.25992 23.9982C1.48517 24.01 1.70973 23.9644 1.9125 23.8656L23.0198 13.5687C23.3137 13.4253 23.5615 13.2022 23.7348 12.9249C23.9081 12.6475 24 12.327 24 12C24 11.673 23.9081 11.3525 23.7348 11.0751C23.5615 10.7978 23.3148 10.5747 23.0209 10.4313Z",
}