import {
  AddIcon,
  AddUserIcon,
  AttachIcon,
  ArrowLeft,
  ArrowRight,
  CalendarIcon,
  CardTickIcon,
  CheckIcon,
  ClipboardListIcon,
  ClockIcon,
  CloseIcon,
  CloudIcon,
  ConfigurationIcon,
  CrossIcon,
  DeleteIcon,
  DesignToolIcon,
  DiogramIcon,
  DownloadIcon,
  EmojiIcon,
  ExitIcon,
  EyeIcon,
  EyeSlasIcon,
  FileSaveIcon,
  FilterIcon,
  FilterIcon2,
  FolderIcon,
  HomeIcon,
  ImageIcon,
  LinkSquareIcon,
  MailNotificationsIcon,
  MedalIcon,
  MessageSend,
  MessageNotifIcon,
  MessageWithLinesIcon,
  NotificationsIcon,
  PeopleIcon,
  PhoneIcon,
  ReferenceIcon,
  RouteIcon,
  SearchIcon,
  SettingsIcon,
  SortDownIcon,
  SortUpIcon,
  SuccessIcon,
  TrashIcon,
  UpdateIcon,
  UploadIcon,
  UserIcon,
  VariantIcon,
  VideoIcon,
  VideoListIcon,
  VideoProgressIcon,
  WarningIcon,
  InstagramIcon,
  TelegramIcon,
  VKIcon,
  XIcon,
  YoutubeIcon,
  LinkIcon,
  MessageConvertIcon,
  GiftIcon,
  ListMessagesIcon,
} from './svgIcons'
import { pathT } from '../../types/commonComponentsTypes'

export const AddIconPath = [
  { d: AddIcon.d1, fill: 'currentColor' },
  { d: AddIcon.d2, fill: 'currentColor' },
  { d: AddIcon.d3, fill: 'currentColor' },
] // Plus

export const AddUserIconPath = [
  { d: AddUserIcon.d1, fill: 'currentColor' },
  { d: AddUserIcon.d2, fill: 'currentColor' },
] //folder-2

export const AttachIconPath = [{ d: AttachIcon.d1, fill: 'currentColor' }] // Attach

export const ArrowLeftIconPath: pathT[] = [{
  d: ArrowLeft.d1,
  strokeWidth: '2',
  stroke: '#332F36',
  strokeLinecap: 'round',
  strokeLinejoin: "round",
}]

export const ArrowRightIconPath: pathT[] = [{
  d: ArrowRight.d1,
  strokeWidth: '2',
  stroke: '#332F36',
  strokeLinecap: 'round',
  strokeLinejoin: "round",
}]

export const CalendarIconPath = [
  { d: CalendarIcon.d1, fill: 'currentColor' },
  { d: CalendarIcon.d2, fill: 'currentColor' },
  { d: CalendarIcon.d3, fill: 'currentColor' },
  { d: CalendarIcon.d4, fill: 'currentColor' },
  { d: CalendarIcon.d5, fill: 'currentColor' },
  { d: CalendarIcon.d6, fill: 'currentColor' },
  { d: CalendarIcon.d7, fill: 'currentColor' },
  { d: CalendarIcon.d8, fill: 'currentColor' },
  { d: CalendarIcon.d9, fill: 'currentColor' },
  { d: CalendarIcon.d10, fill: 'currentColor' },
] //calendar

export const CardTickIconPath = [
  { d: CardTickIcon.d1, fill: 'currentColor' },
  { d: CardTickIcon.d2, fill: 'currentColor' },
  { d: CardTickIcon.d3, fill: 'currentColor' },
  { d: CardTickIcon.d4, fill: 'currentColor' },
  { d: CardTickIcon.d5, fill: 'currentColor' },
] //card-tick

export const CheckIconPath = [{ d: CheckIcon.d1, fill: 'currentColor' }] //Check

export const ClipboardListIconPath = [
  { d: ClipboardListIcon.d1, fill: 'currentColor' },
  { d: ClipboardListIcon.d2, fill: 'currentColor' },
  { d: ClipboardListIcon.d3, fill: 'currentColor' },
] //layers

export const ClockIconPath = [
  { d: ClockIcon.d1, fill: 'currentColor' },
  { d: ClockIcon.d2, fill: 'currentColor' },
] //clock

export const CloseIconPath = [
  { d: CloseIcon.d1, fill: 'currentColor' },
  { d: CloseIcon.d2, fill: 'currentColor' },
  { d: CloseIcon.d3, fill: 'currentColor' },
] //close-circle

export const CloudIconPath = [{ d: CloudIcon.d1, fill: 'currentColor' }] //cloud

export const ConfigurationIconPath = [
  { d: ConfigurationIcon.d1, fill: 'currentColor' },
  { d: ConfigurationIcon.d2, fill: 'currentColor' },
  { d: ConfigurationIcon.d3, fill: 'currentColor' },
] //teacher

export const CrossIconPath = [
  { d: CrossIcon.d1, fill: 'currentColor' },
  { d: CrossIcon.d2, fill: 'currentColor' },
] //Cross

export const DeleteIconPath = [{ d: DeleteIcon.d1, fill: 'currentColor' }] //trush-square

export const DesignToolIconPath = [
  { d: DesignToolIcon.d1, fill: 'currentColor' },
  { d: DesignToolIcon.d2, fill: 'currentColor' },
  { d: DesignToolIcon.d3, fill: 'currentColor' },
  { d: DesignToolIcon.d4, fill: 'currentColor' },
  { d: DesignToolIcon.d5, fill: 'currentColor' },
  { d: DesignToolIcon.d6, fill: 'currentColor' },
  { d: DesignToolIcon.d7, fill: 'currentColor' },
] //designtools

export const DiogramIconPath = [
  { d: DiogramIcon.d1, fill: 'currentColor' },
  { d: DiogramIcon.d2, fill: 'currentColor' },
] //diogram

export const DownloadIconPath = [{ d: DownloadIcon.d1, fill: 'dodgerblue' }] //download

export const EmojiIconPath = [
  { d: EmojiIcon.d1, fill: 'currentColor' },
  { d: EmojiIcon.d2, fill: 'currentColor' },
  { d: EmojiIcon.d3, fill: 'currentColor' },
  { d: EmojiIcon.d4, fill: 'currentColor' },
] //emoji-normal

export const ExitIconPath = [{ d: ExitIcon.d1, fill: 'currentColor' }] //Exit

export const EyeIconPath: pathT[] = [
  { d: EyeIcon.d1, fill: 'currentColor', fillRule: 'evenodd', clipRule: 'evenodd' },
  { d: EyeIcon.d2, fill: 'currentColor', fillRule: 'evenodd', clipRule: 'evenodd' },
]
//eye

export const SortIconDownPath = [
  { d: SortDownIcon.d1, fill: 'currentColor', fillRule: 'evenodd', clipRule: 'evenodd' },
  { d: SortDownIcon.d2, fill: 'currentColor', fillRule: 'evenodd', clipRule: 'evenodd' },
]
export const SortIconUpPath = [
  { d: SortUpIcon.d1, fill: 'currentColor' },
  { d: SortUpIcon.d2, fill: 'currentColor' },
]
export const EyeSlasIconPath = [
  { d: EyeSlasIcon.d1, fill: 'currentColor' },
  { d: EyeSlasIcon.d2, fill: 'currentColor' },
  { d: EyeSlasIcon.d3, fill: 'currentColor' },
  { d: EyeSlasIcon.d4, fill: 'currentColor' },
  { d: EyeSlasIcon.d5, fill: 'currentColor' },
] //eye-slash

export const FileSaveIconPath = [
  { d: FileSaveIcon.d1, fill: 'currentColor' },
  { d: FileSaveIcon.d2, fill: 'currentColor' },
  { d: FileSaveIcon.d3, fill: 'currentColor' },
  { d: FileSaveIcon.d4, fill: 'currentColor' },
] //vuesax

export const FilterIconPath = [{ d: FilterIcon.d1, fill: 'currentColor' }] //Filter

export const FilterIcon2Path = [
  { d: FilterIcon2.d1, fill: 'currentColor' },
  { d: FilterIcon2.d2, fill: 'currentColor' },
] //Filter2

export const FolderIconPath = [
  { d: FolderIcon.d1, fill: 'currentColor' },
  { d: FolderIcon.d2, fill: 'currentColor' },
] //folder-2

export const HomeIconPath = [
  { d: HomeIcon.d1, fill: 'currentColor' },
  { d: HomeIcon.d2, fill: 'currentColor' },
] //home-2

export const ImageIconPath = [
  { d: ImageIcon.d1, fill: 'currentColor' },
  { d: ImageIcon.d2, fill: 'currentColor' },
] //card-tick

export const LinkSquareIconPath = [
  { d: LinkSquareIcon.d1, fill: 'currentColor' },
  { d: LinkSquareIcon.d2, fill: 'currentColor' },
] //link-square

export const MailNotificationsIconPath = [
  { d: MailNotificationsIcon.d1, fill: 'currentColor' },
  { d: MailNotificationsIcon.d2, fill: 'currentColor' },
  { d: MailNotificationsIcon.d3, fill: 'currentColor' },
] //sms-notification

export const MedalIconPath: pathT[] = [
  { d: MedalIcon.d1, fill: '#357EEB', fillRule: 'evenodd', clipRule: 'evenodd' },
  { d: MedalIcon.d2, fill: '#357EEB' },
  { d: MedalIcon.d3, fill: '#357EEB', fillRule: 'evenodd', clipRule: 'evenodd' },
  { d: MedalIcon.d4, fill: 'white', fillRule: 'evenodd', clipRule: 'evenodd' },
] //Medal

export const MessageConvertIconPath: pathT[] = [
  { d: MessageConvertIcon.d1, fill: '#357EEB' },
  { d: MessageConvertIcon.d2, fill: '#357EEB' },
] // сообщение конверт 0 0 18 18

export const MessageSendPath: pathT[] = [
  { d: MessageSend.d1, fill: '#357EEB' },
  { d: MessageSend.d2, fill: '#357EEB' },
]

export const MessageNotifIconPath = [
  { d: MessageNotifIcon.d1, fill: 'currentColor' },
  { d: MessageNotifIcon.d2, fill: 'currentColor' },
  { d: MessageNotifIcon.d3, fill: 'currentColor' },
  { d: MessageNotifIcon.d4, fill: 'currentColor' },
  { d: MessageNotifIcon.d5, fill: 'currentColor' },
] //message

export const MessageWithLinesIconPath = [
  { d: MessageWithLinesIcon.d1, fill: 'currentColor' },
  { d: MessageWithLinesIcon.d2, fill: 'currentColor' },
  { d: MessageWithLinesIcon.d3, fill: 'currentColor' },
] //message-text

export const NotificationsIconPath = [
  { d: NotificationsIcon.d1, fill: 'currentColor' },
  { d: NotificationsIcon.d2, fill: 'currentColor' },
  { d: NotificationsIcon.d3, fill: 'currentColor' },
] //notification

export const PeopleIconPath = [
  { d: PeopleIcon.d1, fill: 'currentColor' },
  { d: PeopleIcon.d2, fill: 'currentColor' },
  { d: PeopleIcon.d3, fill: 'currentColor' },
  { d: PeopleIcon.d4, fill: 'currentColor' },
  { d: PeopleIcon.d5, fill: 'currentColor' },
  { d: PeopleIcon.d6, fill: 'currentColor' },
] //people

export const PhoneIconPath = [{ d: PhoneIcon.d1, fill: 'currentColor' }] //Phone

export const ReferenceIconPath = [
  { d: ReferenceIcon.d1, fill: 'currentColor' },
  { d: ReferenceIcon.d2, fill: 'currentColor' },
] //Variant52 (!)

export const RouteIconPath = [{ d: RouteIcon.d1, fill: 'currentColor' }] //route-square

export const SearchIconPath = [
  { d: SearchIcon.d1, fill: 'currentColor' },
  { d: SearchIcon.d2, fill: 'currentColor' },
] //search-normal

export const SettingsIconPath = [
  { d: SettingsIcon.d1, fill: 'currentColor' },
  { d: SettingsIcon.d2, fill: 'currentColor' },
] //setting-2

export const SuccessIconPath = [{ d: SuccessIcon.d1, fill: 'currentColor' }] //tick-circle

export const TrashIconPath = [
  { d: TrashIcon.d1, fill: 'currentColor' },
  { d: TrashIcon.d2, fill: 'currentColor' },
  { d: TrashIcon.d3, fill: 'currentColor' },
]

export const UpdateIconPath: pathT[] = [
  {
    d: UpdateIcon.d1,
    fill: 'currentColor',
    stroke: '#357EEB',
    strokeWidth: '1.5',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
] //refresh-2

export const UploadIconPath: pathT[] = [{ d: UploadIcon.d1, fill: 'currentColor' }]

export const UserIconPath = [
  { d: UserIcon.d1, fill: 'dodgerblue' },
  { d: UserIcon.d2, fill: 'dodgerblue' },
] //profile

export const VariantIconPath = [
  { d: VariantIcon.d1, fill: 'currentColor' },
  { d: VariantIcon.d2, fill: 'currentColor' },
  { d: VariantIcon.d3, fill: 'currentColor' },
  { d: VariantIcon.d4, fill: 'currentColor' },
] //Variant51

export const VideoIconPath = [{ d: VideoIcon.d1, fill: 'currentColor' }]

export const VideoListIconPath = [{ d: VideoListIcon.d1, fill: 'currentColor' }] //Video
export const VideoProgressIconPath = [{ d: VideoProgressIcon.d1, fill: 'currentColor' }] //Videolist
export const WarningIconPath = [
  { d: WarningIcon.d1, fill: 'currentColor' },
  { d: WarningIcon.d2, fill: 'currentColor' },
  { d: WarningIcon.d3, fill: 'currentColor' },
] //vuesax

export const InstagramIconPath: pathT[] = [
  {
    d: InstagramIcon.d1,
    stroke: '#332F36',
    strokeWidth: '2.66667',
    strokeLinejoin: 'round',
  },
  {
    d: InstagramIcon.d2,
    stroke: '#332F36',
    strokeWidth: '2.66667',
    strokeLinejoin: 'round',
  },
  {
    d: InstagramIcon.d3,
    fill: 'currentColor',
  },
] //instagram

export const TelegramIconPath = [
  {
    d: TelegramIcon.d1,
    fill: 'currentColor',
  },
] //telegram

export const VKIconPath = [
  {
    d: VKIcon.d1,
    fill: '#332F36',
  },
] //vk

export const YoutubeIconPath = [
  {
    d: YoutubeIcon.d1,
    fill: 'currentColor',
  },
  {
    d: YoutubeIcon.d2,
    fill: 'white',
  },
] //youtube

export const LinkIconPath = [
  {
    d: LinkIcon.d1,
    fill: '#332F36',
  },
] //link

export const XIconPath: pathT[] = [
  {
    d: XIcon.d1,
    stroke: '#332F36',
    strokeWidth: '2',
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
  },
] //x

export const GiftIconPath: pathT[] = [
  {
    d: GiftIcon.d,
    fill: '#332F36',
    clipRule: 'evenodd',
    fillRule: 'evenodd',
  },
] // 0 0 38 41 gift navbar icon

export const ListMessagesIconPath = [
  { d: ListMessagesIcon.d1, fill: 'currentColor' },
  { d: ListMessagesIcon.d2, fill: 'currentColor' },
  { d: ListMessagesIcon.d3, fill: 'currentColor' },
  { d: ListMessagesIcon.d4, fill: 'currentColor' },
]


