import { pathT } from 'types/commonComponentsTypes'

export const penIconPath: pathT[] = [
  {
    d: 'M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z',
    fill: '#292D32',
  },
  {
    d: 'M17.3404 10.9489C17.3204 10.9489 17.2904 10.9489 17.2704 10.9489C14.1504 10.6389 11.6404 8.26887 11.1604 5.16887C11.1004 4.75887 11.3804 4.37887 11.7904 4.30887C12.2004 4.24887 12.5804 4.52887 12.6504 4.93887C13.0304 7.35887 14.9904 9.21887 17.4304 9.45887C17.8404 9.49887 18.1404 9.86887 18.1004 10.2789C18.0504 10.6589 17.7204 10.9489 17.3404 10.9489Z',
    fill: '#292D32',
  },
  {
    d: 'M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z',
    fill: '#292D32',
  },
]
