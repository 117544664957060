import {FC} from "react";


export const SearchIcon: FC = () => {
    return <defs>
        <linearGradient id="paint0_linear_16610_23078" x1="1.5625" y1="13.75" x2="25.9375" y2="13.75"
                        gradientUnits="userSpaceOnUse">
            <stop stopColor="#357EEB"/>
            <stop offset="1" stopColor="#357EEB"/>
        </linearGradient>
        <linearGradient id="paint1_linear_16610_23078" x1="22.4883" y1="25.4937" x2="28.3253" y2="25.4937"
                        gradientUnits="userSpaceOnUse">
            <stop stopColor="#357EEB"/>
            <stop offset="1" stopColor="#357EEB"/>
        </linearGradient>
    </defs>
}